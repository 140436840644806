import constants from "@/service/constant/store"

const padTime = time => (time < 10 ? "0" : "") + time

const state = {
  timer: null,
  timeOut: 3 * 60,
  minutes: "00",
  seconds: "00",
}

const mutations = {
  [constants.RESET_TIMER]: state => {
    state.timer = null
    state.timeOut = 3 * 60
    state.minutes = "00"
    state.seconds = "00"
  },
  [constants.SET_MINUTES]: state => (state.minutes = padTime(Math.floor(state.timeOut / 60))),
  [constants.SET_SECONDS]: state => (state.seconds = padTime(state.timeOut - state.minutes * 60)),
  [constants.START_TIMER]: (state, payload) => {
    state.timer = payload.timer
    state.timeOut = payload.timeOut
  },
  [constants.COUNT_DOWN]: state => (state.timeOut = state.timeOut - 1),
}

const actions = {
  [constants.RESET_TIMER]: ({ commit }) => {
    clearInterval(state.timer)
    commit(constants.RESET_TIMER)
  },
  [constants.START_TIMER]: ({ dispatch, commit }, TimeOut) => {
    commit(constants.START_TIMER, {
      timer: setInterval(() => dispatch(constants.COUNT_DOWN), 1000),
      timeOut: TimeOut,
    })
  },
  [constants.COUNT_DOWN]: ({ dispatch, commit }) => {
    commit(constants.COUNT_DOWN)
    commit(constants.SET_MINUTES)
    commit(constants.SET_SECONDS)
    if (state.timeOut === 0) {
      alert("유효시간이 초과되었습니다. 인증번호를 다시 받으시기 바랍니다.")
      dispatch(constants.RESET_TIMER)
      commit(constants.RESET_CERT_DATA)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
