import Vue from "vue"
import Vuex from "vuex"
import constants from "@/service/constant/store"
import index from "@/service/constant/index"

import cert from "./modules/cert"
import timer from "./modules/timer"
import center from "./modules/center"

Vue.use(Vuex)

const state = {
  loading: false,
  navOpened: false,
  servImgSRC: "",
  homePop: "",
  noticeLatest: [],
  newsLatest: [],
}

const mutations = {
  [constants.IS_LOADING]: state => (state.loading = !state.loading),
  [constants.NAV_HOVER]: state => (state.navOpened = !state.navOpened),
  [constants.CLEAR_HOMEPOP]: state => (state.homePop = ""),
  [constants.FETCH_SERV_IMGSRC]: (state, payload) => (state.servImgSRC = payload),
  [constants.SET_MAIN_NOTICELATEST]: (state, payload) => (state.noticeLatest = payload),
  [constants.SET_MAIN_NEWSLATEST]: (state, payload) => (state.newsLatest = payload),
  [constants.SET_MAIN_HOMEPOP]: (state, payload) => (state.homePop = payload),
}

const actions = {
  [constants.FETCH_SERV_IMGSRC]: ({ commit }) => {
    let imgSrc = index.IMG_LIVE
    if (window.location.host.indexOf("dev") == 0 || window.location.host.indexOf("local") == 0) {
      imgSrc = index.IMG_DEV
    }
    commit(constants.FETCH_SERV_IMGSRC, imgSrc)
  },
}

const modules = {
  cert,
  timer,
  center,
}

export default new Vuex.Store({
  state,
  mutations,
  modules,
  actions,
})
