<template>
  <LayerPopup :popId="'loading'">
    <template v-slot:popContents>
      <div class="loading_cirlce"></div>
    </template>
  </LayerPopup>
</template>

<script>
import LayerPopup from "@/components/LayerPopup.vue"

export default {
  name: "Loading",
  components: {
    LayerPopup,
  },
}
</script>
