import constants from "@/service/constant/store"

const state = {
  caseQ: "",
  PName: "",
  PNumber: "",
  Email: "",
  Title: "",
  Contents: "",
  termsQ: false,
}

const getters = {
  contsCount(state) {
    return state.Contents.length
  },
  checkHasData(state) {
    if (state.caseQ && state.PName && state.PNumber && state.Email && state.Title && state.Contents && state.termsQ) {
      return false
    } else return true
  },
}

const mutations = {
  [constants.RESET_CENTER_DATA]: state => {
    state.caseQ = ""
    state.PName = ""
    state.PNumber = ""
    state.Email = ""
    state.Title = ""
    state.Contents = ""
    state.termsQ = false
  },
  [constants.CHG_PNUM]: (state, payload) => (state.PNumber = payload),
}

export default {
  state,
  getters,
  mutations,
}
