<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.956" height="14.252">
    <path
      data-name="Color Overlay"
      d="m.843 14 6.35-6.547 2.593 2.47-7.2 4.1A1.7 1.7 0 0 1 .843 14zM0 12.534V1.715a1.7 1.7 0 0 1 .242-.87l6.316 6.008-6.333 6.53A1.707 1.707 0 0 1 0 12.534zm7.8-5.708 2.267-2.337 2.018 1.148a1.708 1.708 0 0 1 0 2.974l-1.507.858zM.871.232a1.71 1.71 0 0 1 1.713 0l6.7 3.813-2.116 2.177z"
      :style="`fill: ${fill}`"
    />
  </svg>
</template>

<script>
export default {
  name: "PlayStore",
  props: {
    fill: {
      type: String,
    },
  },
}
</script>
