const navigationGuard = (to, from, next) => {
  const innerWidth = window.innerWidth
  /* 사용자 플랫폼 감지 리다이렉션 */
  const url = process.env.NODE_ENV === "production" ? "https://www.mfinder.co.kr" : "https://dev-www.mfinder.co.kr"
  if (innerWidth) {
    if (innerWidth <= 1200) {
      console.log("MOBILE")
      next()
      window.scrollTo(0, 0)
    } else {
      console.log("PC")
      window.location.href = url + to.path
    }
  } else {
    console.log("No Platform")
  }
}

export default navigationGuard
