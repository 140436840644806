import Vue from "vue"
import VueRouter from "vue-router"
import Layout from "@/layouts/Layout.vue"
import navigationGuard from "./navigationGuard"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "home",
    children: [
      {
        path: "home",
        name: "HOME",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "intro",
        name: "서비스 소개",
        component: () => import("@/views/Intro.vue"),
      },
      {
        path: "faq",
        name: "FAQ",
        component: () => import("@/views/Faq.vue"),
      },
      {
        path: "cancel",
        name: "서비스 해지",
        component: () => import("@/views/Cancel.vue"),
      },
      {
        path: "center",
        name: "고객센터",
        component: () => import("@/views/Center.vue"),
      },
      {
        path: "notice",
        name: "공지사항",
        component: () => import("@/views/Notice.vue"),
        children: [
          {
            path: "",
            name: "공지사항리스트",
            component: () => import("@/views/NoticeList.vue"),
          },
          {
            path: "detail/:idx",
            name: "공지사항상세",
            component: () => import("@/views/NoticeDetail.vue"),
          },
        ],
      },
      {
        path: "news",
        name: "새소식",
        component: () => import("@/views/News.vue"),
        children: [
          {
            path: "",
            name: "새소식리스트",
            component: () => import("@/views/NewsList.vue"),
          },
        ],
      },
      {
        path: "terms",
        name: "이용약관",
        component: () => import("@/views/Terms.vue"),
        redirect: "terms/10/1",
        children: [
          {
            path: ":telecom/:termsIdx",
            name: "이용약관상세",
            component: () => import("@/views/TermsDetail.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/ask",
    name: "문의약관",
    component: () => import("@/views/Ask.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  navigationGuard(to, from, next)
})

export default router
