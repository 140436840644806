export default {
  // common
  IS_LOADING: "common/isLoading",
  NAV_HOVER: "common/navHover",
  CLEAR_HOMEPOP: "common/clearHomePop",
  FETCH_SERV_IMGSRC: "common/fetchServImgSrc",
  SET_MAIN_NOTICELATEST: "common/setMainNoticeList",
  SET_MAIN_NEWSLATEST: "common/setMainNewsList",
  SET_MAIN_HOMEPOP: "common/setMainHomePop",
  // cert
  SET_TRANID: "cert/setTranId",
  RESET_CERT_DATA: "cert/resetCertData",
  CHANGE_TEL: "cert/changeTel",
  CHG_PHONENUM: "cert/changePhoneNum",
  CHG_CERTNUM: "cert/changeCertNum",
  // center
  RESET_CENTER_DATA: "center/resetCenterData",
  CHG_PNUM: "center/changePNum",
  // timer
  RESET_TIMER: "timer/resetTimer",
  SET_MINUTES: "timer/setMinutes",
  SET_SECONDS: "timer/setSeconds",
  START_TIMER: "timer/startTimer",
  COUNT_DOWN: "timer/countdown",
}
