<template>
  <div :id="popId" class="ly_pop">
    <slot name="popContents"></slot>
  </div>
</template>

<script>
export default {
  name: "LayerPopup",
  props: {
    popId: {
      type: String,
    },
  },
}
</script>
