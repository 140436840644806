import axios from "axios"
import constant from "@/service/constant"

const utils = {
  install(Vue) {
    Vue.prototype.$openLink = url => {
      window.open(url)
    }
    Vue.prototype.$validateRegExp = (value, limit) => {
      const replaceValue = value.replace(/[^0-9]/g, "")
      return replaceValue.length > limit ? replaceValue.substring(0, limit) : replaceValue
    }
    Vue.prototype.$changeDateFormat = format => {
      let year = format.getFullYear()
      let month = format.getMonth() + 1
      if (month < 10) month = "0" + month
      let date = format.getDate()
      if (date < 10) date = "0" + date
      return `${year}-${month}-${date}`
    }
    Vue.prototype.$handleFixDown = () => {
      const header = document.querySelector("#header")
      const footer = document.querySelector("#footer")
      const footInfo = footer.querySelector(".foot_info")
      const appDown = document.querySelector(".app_down.fixed")

      const scrollbarHeight = window.innerHeight * (window.innerHeight / document.body.offsetHeight)
      const footerTop =
        document.body.offsetHeight -
        footer.offsetHeight -
        footInfo.offsetHeight -
        header.offsetHeight -
        appDown.offsetHeight -
        scrollbarHeight
      const scrollTop = document.documentElement.scrollTop + 150
      if (scrollTop >= footerTop) appDown.classList.add("hidden")
      else appDown.classList.remove("hidden")
    }
    Vue.prototype.$guid = () => {
      function _p8(s) {
        let p = (Math.random().toString(16) + "000000000").substr(2, 8)
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p
      }
      return _p8() + _p8(true) + _p8(true) + _p8()
    }
    Vue.prototype.$SRSServer = httpData => {
      let hostServer = constant.MHRS_LIVE
      if (window.location.host.indexOf("dev") >= 0 || window.location.host.indexOf("local") >= 0) {
        hostServer = constant.MHRS_DEV
      } else if (window.location.host.indexOf("192.") >= 0) {
        hostServer = "http://192.168.30.15:2038"
      }

      return axios.post(hostServer, JSON.stringify(httpData))
    }
  },
}

export default utils
