<template>
  <footer id="footer">
    <slot name="appdown"></slot>
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>
