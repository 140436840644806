<template>
  <div id="nav">
    <div class="nav_btn" @click="$openLink($constant.LOCATION_REMOTE)">
      <button type="button">분실폰찾기 로그인</button>
    </div>
    <div class="nav_btn burger" :class="$store.state.navOpened && 'opened'" @click.stop="handleNav">
      <button type="button">
        <i class="bar" v-for="n in 3" :key="n"></i>
      </button>
    </div>
    <nav class="nav_wrap" :class="$store.state.navOpened && 'opened'">
      <div class="nav_menu">
        <ul class="nav_list" @click.stop="handleNav">
          <li v-for="(item, idx) in links" :key="idx">
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="blur" @click.stop="handleNav"></div>
    </nav>
  </div>
</template>

<script>
import constants from "@/service/constant/store"

export default {
  name: "Nav",
  data() {
    return {
      links: [
        {
          path: "/home",
          name: "HOME",
        },
        {
          path: "/intro",
          name: "서비스 소개",
        },
        {
          path: "/faq",
          name: "FAQ",
        },
        {
          path: "/notice",
          name: "공지사항",
        },
        {
          path: "/news",
          name: "새소식",
        },
        {
          path: "/center",
          name: "고객센터",
        },
        {
          path: "/cancel",
          name: "서비스 해지",
        },
        {
          path: "/terms",
          name: "이용약관",
        },
      ],
    }
  },
  methods: {
    handleNav() {
      const body = document.querySelector("body")
      body.classList.toggle("nav_opened")
      this.$store.commit(constants.NAV_HOVER)
    },
  },
}
</script>
