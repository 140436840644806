<template>
  <div class="sub_top" :class="bgClass">
    <div class="desc">
      <span v-html="subTitle"></span>
      <strong v-html="subText" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SubPageTop",
  computed: {
    bgClass() {
      if (this.$route.name === "이용약관상세") return "terms"
      else if (this.$route.name === "공지사항" || this.$route.name === "공지사항상세") return "notice"
      else return this.$route.path.replace("/", "")
    },
    subTitle() {
      let upperPath = ""
      if (this.$route.name === "이용약관상세") upperPath = "TERMS"
      else if (this.$route.name === "공지사항" || this.$route.name === "공지사항상세") upperPath = "NOTICE"
      else upperPath = this.$route.path.replace("/", "").toUpperCase()
      return this.$constant[`SUBPAGE_${upperPath}`].TITLE
    },
    subText() {
      let upperPath = ""
      if (this.$route.name === "이용약관상세") upperPath = "TERMS"
      else if (this.$route.name === "공지사항" || this.$route.name === "공지사항상세") upperPath = "NOTICE"
      else upperPath = this.$route.path.replace("/", "").toUpperCase()
      return this.$constant[`SUBPAGE_${upperPath}`].TEXT
    },
  },
}
</script>
