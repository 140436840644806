import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import utils from "./service/utils"
import constant from "@/service/constant"
import VueCookies from "vue-cookies"

import "reset-css"
import "@/styles/index.scss"

Vue.config.productionTip = false
Vue.prototype.$constant = constant
Vue.use(utils)
Vue.use(VueCookies)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
