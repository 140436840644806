import constants from "@/service/constant/store"

const state = {
  phoneHeader: ["010", "011", "016", "017", "018", "019"],
  phoneNumSelect: "010",
  phoneNumInsert: "",
  tel: 0,
  telecom: [
    {
      agency: "skt",
      name: "SKT",
      terms: [],
    },
    {
      agency: "kt",
      name: "KT",
      terms: [],
    },
    {
      agency: "lgu",
      name: "LG U+",
      terms: [],
    },
  ],
  tranID: "",
  CertNumber: "",
}

const mutations = {
  [constants.RESET_CERT_DATA]: state => {
    state.phoneNumSelect = "010"
    state.phoneNumInsert = ""
    state.tel = 0
    state.tranID = ""
    state.CertNumber = ""
  },
  [constants.CHANGE_TEL]: (state, payload) => (state.tel = Number(payload)),
  [constants.SET_TRANID]: (state, payload) => (state.tranID = payload),
  [constants.CHG_PHONENUM]: (state, payload) => (state.phoneNumInsert = payload),
  [constants.CHG_CERTNUM]: (state, payload) => (state.CertNumber = payload),
}

const actions = {
  [constants.SET_TRANID]: ({ commit }, TranID) => {
    commit(constants.SET_TRANID, TranID)
  },
  [constants.CHANGE_TEL]: ({ commit }, telIdx) => {
    commit(constants.CHANGE_TEL, telIdx)
  },
}

export default {
  state,
  mutations,
  actions,
}
