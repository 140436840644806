export default {
  // server
  MHRS_LIVE: "https://mhrs.mfinder.co.kr",
  MHRS_DEV: "https://dev-mhrs.mfinder.co.kr",
  // images server
  IMG_LIVE: "https://img.mfinder.co.kr",
  IMG_DEV: "https://dev-img.mfinder.co.kr",
  // location
  LOCATION_STORE: "https://play.google.com/store/apps/details?id=kr.co.datau.mfinder",
  LOCATION_REMOTE: "https://svc.mfinder.co.kr/",
  LOCATION_DATAU: "http://datauniverse.co.kr/",
  LOCATION_BLOG: "https://blog.naver.com/datau1",
  //subpage
  SUBPAGE_INTRO: {
    TITLE: "서비스 소개",
    TEXT: "분실폰을 찾기 위한<br/>다양한 기능을 제공합니다.",
  },
  SUBPAGE_FAQ: {
    TITLE: "FAQ",
    TEXT: "서비스 이용에 대한<br />궁금증을 해결해 드립니다.",
  },
  SUBPAGE_CANCEL: {
    TITLE: "서비스 해지",
    TEXT: "서비스 해지를 위해서<br />본인인증이 필요합니다.",
  },
  SUBPAGE_CENTER: {
    TITLE: "고객센터",
    TEXT: "서비스이용에 어려운점이 있나요?<br />도와드릴게요.",
  },
  SUBPAGE_NOTICE: {
    TITLE: "공지사항",
    TEXT: "서비스 관련 공지사항<br />알려드립니다.",
  },
  SUBPAGE_NEWS: {
    TITLE: "새소식",
    TEXT: "휴대폰분실보호<br />새소식 전해드립니다.",
  },
  SUBPAGE_TERMS: {
    TITLE: "서비스이용약관",
    TEXT: "휴대폰분실보호<br />서비스 이용약관을 알려드립니다.",
  },
}
