<template>
  <header id="header">
    <div class="head_wrap">
      <slot name="logo"></slot>
      <slot name="nav"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
}
</script>
