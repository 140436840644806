<template>
  <div id="wrap">
    <Loading v-if="$store.state.loading" />
    <Header>
      <template v-slot:logo>
        <h1 class="logo" @click="goHome">
          <button type="button"><i class="hide">휴대폰분실보호</i></button>
        </h1>
      </template>
      <template v-slot:nav>
        <Nav />
      </template>
    </Header>
    <template v-if="$route.path !== '/home'">
      <SubPageTop />
    </template>
    <router-view />
    <Footer>
      <template v-slot:appdown>
        <div class="foot_appdown">
          <div class="desc">
            <strong>내 소중한 휴대폰,<br />분실 전에 미리 설치하고 보호하세요!</strong>
            <AppDown :bgColor="'#fff'" :fill="'#000'" :text="'다운로드 받기'" />
          </div>
        </div>
      </template>
      <template>
        <div class="foot_info">
          <div class="desc">
            <ul>
              <li><button type="button" @click="$openLink($constant.LOCATION_DATAU)">회사소개</button></li>
              <li><button type="button" @click="goTerms('terms', 10, 1)">이용약관</button></li>
              <li><button type="button" @click="goTerms('terms', 10, 2)">개인정보취급방침</button></li>
              <li><button type="button" @click="$openLink($constant.LOCATION_BLOG)">서비스 블로그</button></li>
            </ul>
            <p>
              (주)데이터유니버스 대표 : 강원석 | 서울 영등포구 국제금융로2길 32<br />
              고객지원센터 :1811-4031 (공휴일 휴무 09:00 ~ 12:00 / 13:00 ~ 18:00)<br />
              Copyright © 2020 DataUniverse. All Rights Reserved.
            </p>
          </div>
        </div>
      </template>
    </Footer>
    <template v-if="$route.path === '/home' || $route.path === '/intro'">
      <AppDown :bgColor="'#000'" :fill="'#fff'" :text="'앱 다운받기'" class="fixed" />
    </template>
  </div>
</template>

<script>
import Header from "@/layouts/components/Header.vue"
import Nav from "@/layouts/components/Nav.vue"
import Footer from "@/layouts/components/Footer.vue"
import Loading from "@/components/Loading.vue"
import AppDown from "@/components/AppDown.vue"
import SubPageTop from "@/components/SubPageTop.vue"

export default {
  name: "Layout",
  components: {
    Header,
    Nav,
    Footer,
    AppDown,
    Loading,
    SubPageTop,
  },
  methods: {
    goHome() {
      this.$router.push("/home")
    },
    goTerms(path, tel, idx) {
      this.$router.push({
        path: `/${path}/${tel}/${idx}`,
        telecom: tel,
        termsIdx: idx,
      })
    },
  },
}
</script>
