<template>
  <button
    type="button"
    class="app_down"
    :style="`backgroundColor:${bgColor}`"
    @click="$openLink($constant.LOCATION_STORE)"
  >
    <PlayStore :fill="fill" /><span :style="`color:${fill}`">{{ text }}</span>
  </button>
</template>

<script>
import PlayStore from "@/components/PlayStore.vue"

export default {
  name: "AppDown",
  props: {
    openLink: {
      type: Function,
    },
    bgColor: {
      type: String,
    },
    fill: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  components: {
    PlayStore,
  },
}
</script>
